import React, { Suspense } from 'react';
import { useTranslation } from "react-i18next";

const MyTranslate = ({ ns, value }) => {
  const { t } = useTranslation(ns);
  
  return t(value);
};

const Translate = ({ ns, value }) => (
  <Suspense fallback={String(value)}>
    <MyTranslate ns={ns} value={""+value} />
  </Suspense>
);

export default Translate;
