const IconPattern = ({ size = 4, color = "black", className = '' }) => (
  <svg className={`w-${size} h-${size} 
    ${color == 'black' ? '' : ''} 
    ${color == 'gray' ? 'text-gray-500' : 'group-hover:text-primary'} 
    ${color == 'primary' ? 'text-primary' : 'group-hover:text-primary'} 
    ${color == 'green' ? 'text-green-500' : 'group-hover:text-primary'} 
    ${color == 'red' ? 'text-red-500' : 'group-hover:text-primary'} 
    ${color == 'blue' ? 'text-blue-500' : 'group-hover:text-primary'} 
    ${color == 'yellow' ? 'text-yellow-500' : 'group-hover:text-primary'} 
    ${color == 'purple' ? 'text-purple-500' : 'group-hover:text-primary'}
    ${className}`} viewBox="0 0 438.891 438.891" fill="currentColor">
    <path d="m347.968,57.37198l-39.706,0l0,-17.72253c0,-5.73391 -6.269,-8.33995 -12.016,-8.33995l-30.824,0c-7.314,-20.85039 -25.6,-31.27558 -46.498,-31.27558c-20.668,-0.77523 -39.467,11.8689 -46.498,31.27558l-30.302,0c-5.747,0 -11.494,2.60605 -11.494,8.33995l0,17.72253l-39.707,0c-23.53,0.25043 -42.78,18.77014 -43.886,42.22158l0,298.68092c0,22.93562 20.898,39.61553 43.886,39.61553l257.045,0c22.988,0 43.886,-16.67991 43.886,-39.61553l0,-298.67992c-1.106,-23.45244 -20.356,-41.97215 -43.886,-42.22258zm-196.441,-5.2121l28.735,0c5.016,-0.61061 9.045,-4.41791 9.927,-9.38257c3.094,-13.4433 14.915,-23.09326 28.735,-23.45643c13.692,0.41405 25.335,10.09395 28.212,23.45643c0.937,5.13627 5.232,8.99246 10.449,9.38257l29.78,0l0,41.70077l-135.838,0l0,-41.70077zm219.429,346.11559c0,11.46781 -11.494,18.76515 -22.988,18.76515l-257.045,0c-11.494,0 -22.988,-7.29734 -22.988,-18.76515l0,-298.68092c1.066,-11.93674 10.978,-21.15269 22.988,-21.3712l39.706,0l0,26.58429c0.552,5.84066 5.622,10.20968 11.494,9.90438l154.122,0c5.98,0.32625 11.209,-3.9829 12.016,-9.90438l0,-26.58529l39.706,0c12.009,0.2195 21.922,9.43446 22.988,21.3712l0,298.68191l0.001,0z" />
    <path d="m199.36566,278.1226c-4.95714,-5.2134 -13.18659,-5.50745 -18.50423,-0.65877l-42.29448,40.21926l-17.84269,-18.46207c-4.95714,-5.2134 -13.18659,-5.50619 -18.50423,-0.65877c-5.11905,5.35096 -5.11905,13.76988 0,19.12084l27.09417,27.69247c2.34513,2.61995 5.73253,4.06875 9.25148,3.95643c3.48607,-0.04922 6.81149,-1.47151 9.25148,-3.95643l51.54596,-48.79089c5.1102,-4.67704 5.45172,-12.60252 0.76273,-17.69981c-0.24033,-0.26629 -0.49458,-0.51995 -0.76021,-0.76226z" />
    <path d="m331.41927,315.45064l-105.68152,0c-5.07551,0 -9.18974,4.66734 -9.18974,10.42519s4.11423,10.42519 9.18974,10.42519l105.68152,0c5.07551,0 9.18974,-4.66734 9.18974,-10.42519s-4.11423,-10.42519 -9.18974,-10.42519z" />
    <path d="m202.28825,163.36523c-5.05152,-5.31265 -13.43764,-5.6123 -18.85652,-0.67132l-43.0997,40.98498l-18.18238,-18.81356c-5.05152,-5.31265 -13.43764,-5.6123 -18.85652,-0.67132c-5.21651,5.45283 -5.21651,14.03204 0,19.48487l27.61,28.21969c2.38977,2.66983 5.84167,4.14621 9.42762,4.03175c3.55243,-0.05016 6.94117,-1.49953 9.42762,-4.03175l52.52731,-49.7198c5.20749,-4.76608 5.55551,-12.84245 0.77726,-18.03678c-0.24491,-0.27136 -0.50399,-0.52985 -0.77468,-0.77677z" />
    <path d="m330.41926,202.0491l-105.68153,0c-5.07551,0 -9.18974,4.66734 -9.18974,10.42519s4.11423,10.4252 9.18974,10.4252l105.68153,0c5.07551,0 9.18974,-4.66735 9.18974,-10.4252s-4.11423,-10.42519 -9.18974,-10.42519z" />
  </svg>
);

export default IconPattern;
