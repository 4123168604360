import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

function MobileBtn({ isOpen, setIsOpen }) {
  const [mobileShow, setMobileShow] = useState(true);
  const urls = ["home", "client", "article", "settings", "catalog"];

  useEffect(() => {
    // console.log("USE HAMBURGER");
    const pathname = document.location.pathname.slice(1);
    setMobileShow(urls.some(url => pathname.includes(url)));
  }, [document.location.pathname]);

  return (
    <div className={`mobile-btn ${mobileShow ? '' : 'mobile-off'} -ml-2 flex`} data-testid="menuHamburger" >
      <button type="button" onClick={() => setIsOpen(!isOpen)} className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white">
        <svg className={`${isOpen ? 'hidden' : 'block'} block h-6 w-6`} stroke="currentColor" fill="none" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
        <svg className={`${!isOpen ? 'hidden' : 'block'} block h-6 w-6`} stroke="currentColor" fill="none" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
  );
}

MobileBtn.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default MobileBtn;
