const IconExcel = ({ size = 5, color = 'gray', className = '' }) => (
  <svg
    className={`${className} w-${size} h-${size} text-${color}-500`}
    fill="currentColor"
    viewBox="0 0 512.001 512.001"
  >
    <g>
      <g>
        <path d="M463.996,126.864L340.192,3.061C338.232,1.101,335.574,0,332.803,0H95.726C67.725,0,44.944,22.782,44.944,50.784v410.434
          c0,28.001,22.781,50.783,50.783,50.783h320.547c28.001,0,50.783-22.781,50.783-50.783V134.253
          C467.056,131.482,465.955,128.824,463.996,126.864z M343.254,35.678h0.001l88.126,88.126h-58.242
          c-7.984,0-15.49-3.109-21.134-8.753c-5.643-5.643-8.752-13.148-8.751-21.131V35.678z M446.159,461.217
          c-0.001,16.479-13.407,29.885-29.885,29.885H95.726c-16.479,0-29.885-13.406-29.885-29.885V50.784
          c0.001-16.479,13.407-29.886,29.885-29.886h226.631v73.021c-0.002,13.565,5.28,26.318,14.872,35.909
          c9.591,9.592,22.345,14.874,35.911,14.874h73.019V461.217z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M376.882,209.049H135.119c-5.771,0-10.449,4.678-10.449,10.449V361.94c0,5.771,4.678,10.449,10.449,10.449h241.763
          c5.771,0,10.449-4.678,10.449-10.449V219.498C387.331,213.728,382.652,209.049,376.882,209.049z M245.551,351.492h-99.984V324.92
          h0.001h99.983V351.492z M245.551,304.022h-99.984v-26.606h0.001h99.983V304.022z M245.551,256.518h-99.984v-26.572h99.984V256.518
          z M366.433,351.492h-99.984V324.92h99.984V351.492z M366.433,304.022h-99.984v-26.606h99.984V304.022z M366.433,256.518h-99.984
          v-26.572h99.984V256.518z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M139.796,392.86h-4.678c-5.771,0-10.449,4.678-10.449,10.449c0,5.771,4.678,10.449,10.449,10.449h4.678
          c5.771,0,10.449-4.678,10.449-10.449C150.245,397.539,145.567,392.86,139.796,392.86z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M275.091,392.86H173.599c-5.771,0-10.449,4.678-10.449,10.449c0,5.771,4.678,10.449,10.449,10.449h101.492
          c5.771,0,10.449-4.678,10.449-10.449C285.54,397.539,280.862,392.86,275.091,392.86z"/>
      </g>
    </g>
  </svg>
);

export default IconExcel;
